export const environment = {
    production: true,
    casFlowTriggerUrl: 'https://auth.studieplekken.ugent.be/auth/login/cas',
    hoGentFlowTriggerUrl: 'https://auth.studieplekken.ugent.be/auth/login/hogent',
    arteveldeHSFlowTriggerUrl: 'https://auth.studieplekken.ugent.be/auth/login/artevelde',
    lucaFlowTriggerUrl: 'https://auth.studieplekken.ugent.be/auth/login/luca',
    odiseeFlowTriggerUrl: 'https://auth.studieplekken.ugent.be/auth/login/odisee',
    stadGentFlowTriggerUrl: 'https://auth.studieplekken.ugent.be/auth/login/stadgent',
    kulFlowTriggerUrl: 'https://auth.studieplekken.ugent.be/auth/login/kuleuven',
    otherFlowTriggerUrl: 'https://auth.studieplekken.ugent.be/auth/local/login',

    accessToken: "pk.eyJ1IjoiYXVyaXNhdWRlbnRpcyIsImEiOiJja3M3cGdqN24xMnNsMm5zM2tlN2d4a3MxIn0.i69TQAR5E1VCJJXMP_2QlA",

    useExternalDashboard: true,
    externalDashboardUrl: "https://stad.gent/nl/student-gent/studeren/bloklocaties",
    showStagingWarning: false

};
